import Mark from 'mark.js/src/vanilla.js'

export const useMarkJS = () => {
  // mark.js selector class
  const markSelectorClass = 'mark-result'

  function mark(text: string) {
    if (document) {
      const instance = new Mark(document.querySelectorAll(`.${markSelectorClass}`))
      instance.mark(text)
    }
  }

  function unmark() {
    if (document) {
      const instance = new Mark(document.querySelectorAll(`.${markSelectorClass}`))
      instance.unmark()
    }
  }

  return {
    markSelectorClass,
    mark,
    unmark,
  }
}
